import React from 'react';
import settings from '../../../settings';
import TeamMember from '../team/teammember';

// Instructor's Images
const AaronRankinPopup = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Aaron_Rankin_popUp_MOTORRAD.jpg`;
const AaronRankin = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/NEW_Aaron_Rankin_2.jpg`;
const GregReynolds = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Greg_Reynolds_SML_MOTORRAD.jpg`
const GregReynoldsPopup = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Greg_Reynolds_popUp_MOTORRAD.jpg`;
const RichardFewPopup = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Richard_Few_popUp_MOTORRAD.jpg`;
const RichardFew = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Richard_Few_2.jpg`;
const RicardoRodriguez = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/NEW_Ricardo_Rodriguez_1.jpg`
const RicardoRodriguezPopup = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Ricardo_Rodriguez_popUp_MOTORRAD.jpg`;
const MikeHardinPopup = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Mike_Hardin_popUp_MOTORRAD.jpg`;
const MikeHardin = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Mike_Hardin_SML_MOTORRAD.jpg`;
const MelindaJustus = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Melinda_Justus_1.jpg`
const MelindaJustusPopup = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Melinda_Justus_popUp_MOTORRAD.jpg`;

const MichaelBass = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Michael_Bass_2.jpg`;
const KatieBenson = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Katie_Benson_1.jpg`;
const JodiDriesenGulia = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Jody_Gulia_2.jpg`;
const ChrisJohns = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Chris_Johns_2.jpg`;
const SamanthaLane = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Samantha_Lane_2.jpg`;
const TerrySmith = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/terry-smith.jpg`;
const CharlesFayettMaynard = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Charles_Maynard_1.jpg`;
const ThomasMcCord = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Thomas_McCord_1.jpg`;
const WendyNicoleNaessens = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/NEW_Wendy_Naessens_2.jpg`;
const RobertoRodriguez = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Roberto_Rodriguez_1.jpg`;
const BrentSeay = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Brent_Seay_1.jpg`;
const CalebSmith = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Caleb_Smith_1.jpg`;
const JDMagan = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/JD-Magan.jpg`;
const JasonWeilnau = `${settings.IMAGES_BASE_URL}/images/motorrad/instructors/Jason_Weilnau_1.jpg`;

const data = [
  {
    id: 1,
    name: 'Aaron Rankin',
    title: 'Chief Instructor',
    image: `${AaronRankin}`,
    popupimage: `${AaronRankinPopup}`,
    introduction:
      'Aaron Rankin’s students range from law enforcement officers to racecar drivers. His goal is to help riders develop confidence in their abilities and achieve a feeling of accomplishment, all with an emphasis on safety – both theirs and others. “It is not the speed that gets you. It’s the sudden stop that hurts!” But no matter how advanced the bike or experienced the rider, “You can never receive too much training. Sign up for classes, trust what the instructor tells you and always practice!”'
  },
  {
    id: 2,
    name: 'Ricardo Rodriguez',
    title: 'Chief Instructor',
    image: `${RicardoRodriguez}`,
    popupimage: `${GregReynoldsPopup}`,
    introduction:
      'Greg Reynolds is a racer who started riding mountain bikes in his teens and off-road motorcycles in his twenties. In addition to competing in the GNCC and NEPG, Reynolds took third overall in the NCHSA Pro Class 2009 and 2010, and third overall in the Mid East Racing 2011. But despite his need for speed, Reynolds’ favorite teaching exercise is Emergency Braking. His advice for new riders? “Take the classes that are offered at the BMW Performance Center – and practice your newfound skills!”'
  },
  {
    id: 3,
    name: 'Michael Bass',
    image: `${MichaelBass}`,
    popupimage: `${RichardFewPopup}`,
    introduction:
      'A rider since the age of 17 with 12 podium finishes in Motocross racing, Richard Few knows his way around a track. He also knows how students have different needs and wants, and adapts instruction techniques to each individual. Students are challenged to be open-minded and expect the unexpected; an easy way to get them to learn and change their riding behavior is the Washboards and Ruts exercise. His advice: “Stay within your limits until they are broadened by proper practice and training.”'
  },
  {
    id: 4,
    name: 'Katie Benson',
    image: `${KatieBenson}`,
    popupimage: `${RicardoRodriguezPopup}`,
    introduction:
      'A motorcycle rider since the age of 7, Ricardo Rodriguez’s twin passions are bikes and teaching. For many years, this BMW Rider Academy pro has given riding confidence to everyone from police to teens, both here in the U.S. and abroad. Emergency Braking (with and without ABS and traction control), Avoidance and Tight Circles are his favorite exercises; rider safety is his major concern. Advice to new riders? “Always look where you want to go, and be as smooth as possible with your controls.”'
  },
  {
    id: 5,
    name: 'Richard Few',
    image: `${RichardFew}`,
    popupimage: `${MikeHardinPopup}`,
    introduction:
      'A first-year trainer at the BMW Rider Academy, Michael Hardin grew up riding dirt bikes in the mountains of South Carolina. His students learn to stay focused and avoid distractions – especially during Emergency Braking at Highway Speeds, his favorite course. And although BMW bikes include traction control, Hardin makes sure students first learn how to handle their bikes without it. His most important advice: “You don’t know what you don’t know. Get professional instruction!”'
  },
  {
    id: 6,
    name: 'Jodi Driesen Gulia',
    image: `${JodiDriesenGulia}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 7,
    name: 'Michael Hardin',
    image: ``,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 8,
    name: 'Chris Johns',
    image: `${ChrisJohns}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 9,
    name: 'Adam Brewer Jones',
    image: ``,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 10,
    name: 'Melinda Justus',
    image: `${MelindaJustus}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 11,
    name: 'Samantha Lane',
    image: `${SamanthaLane}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 12,
    name: 'Danny Ka-Hung Leung',
    image: ``,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 13,
    name: 'Terry Smith',
    image: `${TerrySmith}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 14,
    name: 'Charles Fayett Maynard',
    image: `${CharlesFayettMaynard}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 15,
    name: 'Thomas McCord',
    image: `${ThomasMcCord}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 16,
    name: 'Wendy Nicole Naessens',
    image: `${WendyNicoleNaessens}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 17,
    name: 'Roberto Rodriguez',
    image: `${RobertoRodriguez}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 18,
    name: 'William Brent Seay',
    image: `${BrentSeay}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 19,
    name: 'Caleb Smith',
    image: `${CalebSmith}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 20,
    name: 'JD Magan',
    image: `${JDMagan}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 21,
    name: 'Mackenzie Joy Tricker',
    image: ``,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },
  {
    id: 22,
    name: 'Jason Weilnau',
    image: `${JasonWeilnau}`,
    popupimage: `${MelindaJustusPopup}`,
    introduction:
      'She grew up riding dirt bikes and ATVs, and bought her first sportbike at 18. Today, Melinda Justus is an internationally recognized trainer at the Riding Academy. And while advanced technologies like traction control are integrated into BMW bikes, Justus believes it’s key that students first learn how to operate them proficiently without it. “Keep your eyes up – and take these courses!” – advice she uses to help her students become proficient riders and achieve a sense of accomplishment.'
  },

];

class Team extends React.Component {
  state = {
    show: false,
    teammembers: [],
    activeTeamMember: '',
    modalImage: '',
    modalTitle: '',
    modalDescription: '',
    selectedIndex: 0
  };
  componentDidMount() {
    this.setState({teammembers: data})
  }

  handleModalOpen = (selectedIndex) => {
    this.setState({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })
  }

  handleModalClose = () => {
    this.setState({
      show: false,
      activeTeamMember: ''
    })
  }

  handleNextTeamMember = () => {

    let arr = this.state.teammembers.length;
    let selectedIndex = this.state.activeTeamMember + 1;
    selectedIndex = selectedIndex  % arr;

    this.setState ({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })

  }

  handlePreviousTeamMember = () => {

    let arr = this.state.teammembers.length;
    let selectedIndex = this.state.activeTeamMember;

    if (selectedIndex === 0) {
      selectedIndex = arr - 1;
    } else {
      selectedIndex = selectedIndex - 1;
    }

    this.setState ({
      show: true,
      activeTeamMember: selectedIndex,
      modalImage: this.state.teammembers[selectedIndex].popupimage,
      modalTitle: this.state.teammembers[selectedIndex].name,
      modalDescription: this.state.teammembers[selectedIndex].introduction
    })

  }

  render() {
    const { teammembers, modalDescription, modalImage, modalTitle } = this.state;
    const teammembers_with_img = teammembers.filter(function(member){
      return member.image.length != 0;
    });
    return (
      <section className="team__wrapper">
        <div className="container">
          <h2 className="team__header-text"> <span>Meet Your Instructors</span></h2>
          <div className="team">
            {teammembers_with_img.map((teammember, selectedIndex) => {
              return (
                <React.Fragment key={teammember.id}>
                  <TeamMember
                    name={teammember.name}
                    index={selectedIndex}
                    title={teammember.title}
                    image={teammember.image}
                    // introduction={teammember.introduction}
                    // onModalOpen={this.handleModalOpen}
                    // onModalClose={this.handleModalClose}
                    // onNext={this.handleNextTeamMember}
                    // onPrev={this.handlePreviousTeamMember}
                    // modalImage={modalImage}
                    // modalTitle={modalTitle}
                    // modalDescription={modalDescription}
                  />
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
